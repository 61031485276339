import React, { useEffect } from 'react';
import './Appointment.css'; // Optional for custom styling

const Appointment = () => {
  useEffect(() => {
    const scriptTag = document.createElement('script');
    scriptTag.src = 'https://squareup.com/appointments/buyer/widget/6u3uuiix6kwbe7/LSJDD4CPVQM9T/services';
    scriptTag.async = true;
    document.body.appendChild(scriptTag);

    return () => {
      document.body.removeChild(scriptTag);
    };
  }, []);

  return (
    <div className="container my-5">
      <div className="row">
        <div className="col-md-8 offset-md-2">
          <div className="card shadow-lg p-4 glass">
            <h2 className="text-center mb-4">Book Your Appointment</h2>
            <p className="text-center">
              Please use the form below to book your appointment online with Skincare by Marzi.
            </p>

            {/* Square Appointments iframe */}
            <div className="embed-responsive embed-responsive-16by9">
              <iframe
                src="https://squareup.com/appointments/buyer/widget/6u3uuiix6kwbe7/LSJDD4CPVQM9T"
                className="embed-responsive-item"
                style={{ width: '100%', height: '500px', border: 'none', minHeight: '500px' }}
                allow="payment https://app.squareup.com"
                title="Square Appointments"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Appointment;
