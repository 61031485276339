import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Home.css';
import GlassContainer from '../components/GlassContainer';
import ContactForm from '../components/ContactForm';
import { Carousel } from 'react-bootstrap';
import VideoSection from '../components/VideoSection';

const Home = () => {
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [lightboxImgIndex, setLightboxImgIndex] = useState(0);
  const totalImages = 9;

  const openLightbox = (index) => {
    setLightboxImgIndex(index);
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
    setLightboxImgIndex(0);
  };

  const showNextImage = () => {
    setLightboxImgIndex((prevIndex) => (prevIndex + 1) % totalImages);
  };

  const showPreviousImage = () => {
    setLightboxImgIndex((prevIndex) => (prevIndex - 1 + totalImages) % totalImages);
  };

  return (
    <div className="home">
      {/* Video Section */}
      <VideoSection />

      {/* About Us Section */}
      <section id="about" className="about-section">
        <GlassContainer>
          <h1>Welcome to Skincare by Marzi</h1>
          <img
            src="/images/marzi.jpeg"
            alt="About Us"
            className="about-image img-fluid"
          />
          <h3>Marzi Salehi</h3>
          <h5>Certified Medical Aesthetician</h5>
          <p>
            Marzi is a certified medical aesthetician with over 20 years of
            experience. She is committed to helping you achieve your best skin.
          </p>
          <a href="/appointment" className="btn btn-primary mt-3">
            Book Appointment
          </a>
        </GlassContainer>
      </section>

      {/* Services Section */}
            {/* Services Section */}
            <section id="services" className="services-section">
        <GlassContainer>

        <h2>Our Services</h2>
        <hr className="divider" />

        
        <div className="row">
          <div className="col-md-6 col-lg-4">
            <Link to="/consultation-service" className="custom-link">
              <GlassContainer>
                <div className="service-box">
                  <h3>Consultation</h3>
                  <p>Click to learn more</p>
                </div>
              </GlassContainer>
            </Link>
          </div>

          <div className="col-md-6 col-lg-4" >
            <Link to="/facial-service" className="custom-link">
              <GlassContainer>
                <div className="service-box">
                  <h3>Facial Treatments</h3>
                  <p>Click to learn more</p>
                </div>
              </GlassContainer>
            </Link>
          </div>

          <div className="col-md-6 col-lg-4">
            <Link to="/microdermabrasion-service" className="custom-link">
              <GlassContainer>
                <div className="service-box">
                  <h3>Microdermabrasion</h3>
                  <p>Click to learn more</p>
                </div>
              </GlassContainer>
            </Link>
          </div>

          <div className="col-md-6 col-lg-4">
            <Link to="/microneedling-service" className="custom-link">
              <GlassContainer>
                <div className="service-box">
                  <h3>Microneedling</h3>
                  <p>Click to learn more</p>
                </div>
              </GlassContainer>
            </Link>
          </div>

          <div className="col-md-6 col-lg-4">
            <Link to="/chemical-peel-service" className="custom-link">
              <GlassContainer>
                <div className="service-box">
                  <h3>Chemical Peel</h3>
                  <p>Click to learn more</p>
                </div>
              </GlassContainer>
            </Link>
          </div>

          <div className="col-md-6 col-lg-4">
            <Link to="/dermaplaning-service" className="custom-link">
              <GlassContainer>
                <div className="service-box">
                  <h3>Dermaplaning</h3>
                  <p>Click to learn more</p>
                </div>
              </GlassContainer>
            </Link>
          </div>

          <div className="col-md-6 col-lg-4">
            <Link to="/bodyfacial-service" className="custom-link">
              <GlassContainer>
                <div className="service-box">
                  <h3>Body Facial</h3>
                  <p>Click to learn more</p>
                </div>
              </GlassContainer>
            </Link>
          </div>

          <div className="col-md-6 col-lg-4">
            <Link to="/skintag-service" className="custom-link">
              <GlassContainer>
                <div className="service-box">
                  <h3>Skin Tag Removal</h3>
                  <p>Click to learn more</p>
                </div>
              </GlassContainer>
            </Link>
          </div>

          <div className="col-md-6 col-lg-4">
            <Link to="/hotstone-service" className="custom-link">
              <GlassContainer>
                <div className="service-box">
                  <h3>Hot Stone Massage</h3>
                  <p>Click to learn more</p>
                </div>
              </GlassContainer>
            </Link>
          </div>


        </div>
      </GlassContainer>

      </section>
      {/* ...Your services section remains unchanged... */}

      {/* Gallery Section */}
      <section id="gallery" className="gallery-section my-5">
        <GlassContainer>
          <h2 className="text-center mb-4">Our Work</h2>
          <hr className="divider grid"/>
          <div className="container">
            <div className="row">
              {Array.from({ length: totalImages }, (_, i) => (
                <div key={i} className="col-12 col-md-4 mx-auto">
                  <div className="gallery-item">
                    <img
                      src={`/images/work${i + 1}.png`}
                      alt={`Gallery ${i + 1}`}
                      className="img-fluid rounded"
                      onClick={() => openLightbox(i)}
                    />
                    <div className="logo-overlay">
                      <img
                        src="/images/logo2.png"
                        alt="Website Logo"
                        className="logo-center"
                        onClick={() => openLightbox(i)}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </GlassContainer>
      </section>

      {/* Lightbox */}
      {lightboxOpen && (
        <div className="lightbox" onClick={closeLightbox}>
          <span className="close">&times;</span>
          <div className="lightbox-content">
            <button className="lightbox-arrow up-arrow" onClick={(e) => {e.stopPropagation(); showPreviousImage();}}>
              ↑
            </button>
            <img
              src={`/images/work${lightboxImgIndex + 1}.png`}
              alt={`Gallery ${lightboxImgIndex + 1}`}
              className="lightbox-image"
            />
            <button className="lightbox-arrow down-arrow" onClick={(e) => {e.stopPropagation(); showNextImage();}}>
              ↓
            </button>
          </div>
        </div>
      )}

      {/* Other sections such as Testimonials, Appointment, Contact */}
      {/* Contact Us Section */}
      <section id="contact" className="contact-section glass">
        <h2>Contact Us</h2>
        <hr className="divider" />
        <ContactForm />
      </section>
    </div>
  );
};

export default Home;
