import React, { useState } from 'react';
import './ContactForm.css';

const ContactForm = () => {
  // Generate two random numbers for the math challenge
  const [num1] = useState(Math.floor(Math.random() * 10) + 1);
  const [num2] = useState(Math.floor(Math.random() * 10) + 1);
  const [userAnswer, setUserAnswer] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate the math challenge
    const correctAnswer = num1 + num2;
    if (parseInt(userAnswer) !== correctAnswer) {
      setErrorMessage(`Incorrect answer! Please solve ${num1} + ${num2}`);
      return;
    }

    // If correct, proceed with form submission (this can be an API call)
    alert('Form submitted successfully!');
    setErrorMessage(''); // Clear any error message

    // Reset the form fields (Optional)
    setUserAnswer('');
    e.target.reset(); // Reset form inputs
  };

  return (
    <div className="contact-form">
      <div className="row">
        {/* Contact Information Section */}
        <div className="col-md-6 contact-info map-from">
          <h3>Information</h3>
          <a href='https://maps.app.goo.gl/fFz59YQ1kkgEGG3RA'>
            <p><strong>Address:</strong>1360 Guisachan Rd, Kelowna, BC</p>
          </a>
          <a href='https://maps.app.goo.gl/fFz59YQ1kkgEGG3RA'>
            <p><strong>Address:</strong>V1Y 7X6</p>
          </a>
          <a href='tel:+1 (250) 215-4930'>
            <p><strong>Phone:</strong> +1 (250) 215-4930</p>
          </a>
          <a href='mailto:marzi@marskincare.ca'>
            <p><strong>Email:</strong> marzi@marskincare.ca</p>
          </a>
          {/* Embed Google Map */}
          <div className="map-container">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10285.475115649328!2d-119.4706443!3d49.8731008!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x537d8d2b60909713%3A0x33d8c723903291c5!2sSkincare%20by%20Marzi!5e0!3m2!1sen!2sca!4v1728265664193!5m2!1sen!2sca"
              width="100%" height="350" frameBorder="0" style={{ border: 0 }} allowFullScreen="" aria-hidden="false" tabIndex="0"
              title="Google Map"
            ></iframe>
          </div>
        </div>
        <div className="vertical-divider"></div>

        {/* Contact Form Section */}
        <div className="col-md-6 map-form">
          <h3>Contact Form</h3>
          <form onSubmit={handleSubmit}>
            <div className="form-group text-start">
              <label htmlFor="name">Name</label>
              <input type="text" className="form-control" id="name" placeholder="Enter your name" required />
            </div>
            <div className="form-group text-start">
              <label htmlFor="email">Email</label>
              <input type="email" className="form-control" id="email" placeholder="Enter your email" required />
            </div>
            <div className="form-group text-start">
              <label htmlFor="message">Message</label>
              <textarea className="form-control" id="message" rows="3" placeholder="Your message" required></textarea>
            </div>
            
            {/* Math Challenge */}
            <div className="form-group">
              <label htmlFor="math-challenge">
                Solve this: {num1} + {num2} =
              </label>
              <input
                type="number"
                className="form-control"
                id="math-challenge"
                value={userAnswer}
                onChange={(e) => setUserAnswer(e.target.value)}
                required
              />
            </div>

            {/* Error Message */}
            {errorMessage && <p className="error-message">{errorMessage}</p>}

            {/* Updated Button */}
            <button type="submit" className="btn btn-primary">Submit</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
