import React from 'react';
import GlassContainer from '../components/GlassContainer';
import './ServicePage.css';

const ConsultationService = () => {
  const description = "Skin tag removal is a simple and effective procedure designed to safely eliminate small, benign growths on the skin. This treatment is ideal for those looking to improve the appearance and comfort of their skin, especially in areas where skin tags may cause irritation or discomfort. Removing skin tags can enhance skin smoothness and boost confidence. It’s a quick and minimally invasive option that allows your skin to look and feel its best.";
  const priceList = [
    { service: 'Skin Tag Removal (Up to 5)', price: '$60' },
  ];

  return (
    <GlassContainer>
      <div className="service-page container">
        <h1>Skin Tag Removal</h1>
        <div className="row">
          <div className="col-md-6 order-2 order-md-1">
            <p>{description}</p>
          </div>
          
          <div className="col-md-6 order-1 order-md-2 d-flex justify-content-center">
            <img
              src={"/images/skintag1.jpg"}
              alt={"Skin-Tag-Removal"}
              className="img-fluid"
              style={{ width: '100%', maxWidth: '100%' }}
            />
          </div>
        </div>
        
        {/* Price List */}
        <div className="price-list">
          <h2>Price List</h2>
          <ul className="flex-wrap">
            {priceList.map((item, index) => (
              <li key={index}>
                <strong>{item.service}</strong>: {item.price}
              </li>
            ))}
          </ul>

          {/* Appointment Button */}
          <div className="text-center mt-4 appointment-button">
            <a href='/appointment' className="btn btn-primary">
              Book Your Appointment
            </a>
          </div>
        </div>
      </div>
    </GlassContainer>
  );
};

export default ConsultationService;
