import React from 'react';
import GlassContainer from '../components/GlassContainer';
import './ServicePage.css';

const ConsultationService = () => {
  const description = "Our facials are designed to revitalize your skin by deep cleansing, exfoliation, and hydration. We offer a variety of treatments catered to different skin types and concerns, from acne-prone skin to anti-aging needs. Using high-quality, professional-grade products, our facials provide an instant glow while promoting long-term skin health. Our estheticians customize each facial to address your unique needs, ensuring you leave with refreshed and rejuvenated skin.";
  const priceList = [
    { service: 'Body Facial (60 minutes)', price: '$110' },
    { service: 'Back Facial (60 minutes)', price: '$125' },
    { service: 'Butt Facial (60 minutes)', price: '$125' },
  ];

  return (
    <GlassContainer>
      <div className="service-page container">
        <h1>Body Facial Services</h1>
        <div className="row">
          <div className="col-md-6 order-2 order-md-1">
            <p>{description}</p>
          </div>
          
          <div className="col-md-6 order-1 order-md-2 d-flex justify-content-center">
            <img
              src={"/images/facial1.jpg"}
              alt={"Dermaplaning"}
              className="img-fluid"
              style={{ width: '100%', maxWidth: '100%' }}
            />
          </div>
        </div>
        
        {/* Price List */}
        <div className="price-list">
          <h2>Price List</h2>
          <ul className="flex-wrap">
            {priceList.map((item, index) => (
              <li key={index}>
                <strong>{item.service}</strong>: {item.price}
              </li>
            ))}
          </ul>

          {/* Appointment Button */}
          <div className="text-center mt-4 appointment-button">
            <a href='/appointment' className="btn btn-primary">
              Book Your Appointment
            </a>
          </div>
        </div>
      </div>
    </GlassContainer>
  );
};

export default ConsultationService;
