import React from 'react';
import GlassContainer from '../components/GlassContainer';
import './ServicePage.css';

const ConsultationService = () => {
  const description = "Hot stone massage is a luxurious and therapeutic treatment that uses smooth, heated stones to relax muscles, improve circulation, and promote deep relaxation. The warmth of the stones helps to melt away tension, making it an ideal therapy for stress relief and soothing sore muscles. This treatment not only enhances overall well-being but also creates a calming experience, leaving you feeling balanced, rejuvenated, and deeply refreshed.";
  const priceList = [
    { service: 'Full Body Hot Stone Massage (60 minutes)', price: '$89' },
    { service: 'Full Body Hot Stone Massage (90 minutes)', price: '$115' },

  ];

  return (
    <GlassContainer>
      <div className="service-page container">
        <h1>Hot Stone Massage</h1>
        <div className="row">
          <div className="col-md-6 order-2 order-md-1">
            <p>{description}</p>
          </div>
          
          <div className="col-md-6 order-1 order-md-2 d-flex justify-content-center">
            <img
              src={"/images/hotstone1.jpg"}
              alt={"Hot-Stone-Massage"}
              className="img-fluid"
              style={{ width: '100%', maxWidth: '100%' }}
            />
          </div>
        </div>
        
        {/* Price List */}
        <div className="price-list">
          <h2>Price List</h2>
          <ul className="flex-wrap">
            {priceList.map((item, index) => (
              <li key={index}>
                <strong>{item.service}</strong>: {item.price}
              </li>
            ))}
          </ul>

          {/* Appointment Button */}
          <div className="text-center mt-4 appointment-button">
            <a href='/appointment' className="btn btn-primary">
              Book Your Appointment
            </a>
          </div>
        </div>
      </div>
    </GlassContainer>
  );
};

export default ConsultationService;
